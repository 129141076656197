import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Observable } from "rxjs";
import { EnvService } from "../shared/context/env.service";
// import { environment } from '../../environments/environment';

@Injectable()
export class SlotsService{
    // public config = environment.slotConfig;
    public httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'Cache-Control': 'no-cache'
        })
      };

    constructor(private http: HttpClient, private envService: EnvService){

    }

    buildHeaders() {
      const builtHeaders = this.httpOptions;
        /* istanbul ignore else */
        if (this.envService.environment.APIM_TRACE_HEADER_VALUE !== null) {
          builtHeaders.headers = builtHeaders.headers.set(
              this.envService.environment.APIM_TRACE_HEADER,
              this.envService.environment.APIM_TRACE_HEADER_VALUE
          );
      }
      if (this.envService.environment.SLOT_APIM_SUBSCRIPTION_KEY_VALUE !== null) {
          builtHeaders.headers = builtHeaders.headers.set(
              this.envService.environment.APIM_SUBSCRIPTION_KEY_HEADER,
              this.envService.environment.SLOT_APIM_SUBSCRIPTION_KEY_VALUE
          );
      }
        return builtHeaders;
      }

    getSlotsCapacityReport(searchText): Observable<any>{
        return this.http.get('/assets/data/slot-capacity-report.json');
    }

    getMostViewedStores() {
        return this.http.get('/getStores');
    }

    getCustomWeekReport(weekNumber: number) {
        return this.http.get('/assets/data/slot-capacity-report.json');
      }

      getSlotsCapacityReportupdated(searchText, weekOffset) : Observable<any>{
          return this.http.get(this.envService.environment.SLOT_URI+'/'+parseInt(searchText,10)+'?weekOffset='+weekOffset, this.buildHeaders());
          // return this.http.get(this.config.apimUrl + '/api/v1/report/capacityReport/' +parseInt(searchText,10)+'?weekOffset='+weekOffset, this.buildHeaders());
      }


}