import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { NgCircleProgressModule } from 'ng-circle-progress';
import { PopoverModule } from 'ngx-bootstrap/popover';
import { CustomMaterialModule } from '../material-module';
import { NgxMaskModule } from 'ngx-mask';
// Pipes
import { CurrencyPipe } from '../shared/pipes/currency.pipe';
import { OrderByPipe } from '../shared/pipes/order-by.pipe';
import { FormatNumberPipe } from './utils/format-number.pipe';
import { SafePipe } from '../shared/pipes/safe.pipe';
import { MilitaryToStdPipe } from './pipes/military-time-to-std/military-to-std.pipe';

// Directives
import { NumbersOnlyDirective } from './utils/numbers-only.directive';

// Components
import { AnalyticCircleComponent } from './components/analytic-circle/analytic-circle.component';
import { BanneredButtonComponent } from './components/bannered-button/bannered-button.component';
import { BreadcrumbComponent } from './components/breadcrumb/breadcrumb.component';
import { HeaderComponent } from './components/header/header.component';
import { LoaderComponent } from './components/loader/loader.component';
import { SearchFormComponent } from './components/search-form/search-form.component';
import { StoreSelectComponent } from './components/store-select/store-select.component';
import { CommonBtnComponent } from './components/common-btn/common-btn/common-btn.component';
import { RequiredFieldsTableComponent } from './components/required-fields-table/required-fields-table.component';
import { FilterListPipe } from './pipes/filter-list.pipe';
import { ConfirmDialogComponent } from './components/confirm-dialog/confirm-dialog.component';
import { OrderListPipe } from './pipes/order-list.pipe';
import { BrowserAgnosticDatePipe } from './pipes/browser-agnostic-date.pipe';
import { IntegerInputDirective } from './utils/integer-input.directive';
@NgModule({
  declarations: [
    BanneredButtonComponent,
    AnalyticCircleComponent,
    SearchFormComponent,
    FormatNumberPipe,
    NumbersOnlyDirective,
    HeaderComponent,
    BreadcrumbComponent,
    LoaderComponent,
    StoreSelectComponent,
    CurrencyPipe,
    FilterListPipe,
    OrderByPipe,
    OrderListPipe,
    SafePipe,
    CommonBtnComponent,
    RequiredFieldsTableComponent,
    ConfirmDialogComponent,
    BrowserAgnosticDatePipe,
    IntegerInputDirective,
    MilitaryToStdPipe
  ],
  imports: [
    CommonModule,
    CustomMaterialModule,
    FormsModule,
    ReactiveFormsModule,
    PopoverModule.forRoot(),
    NgCircleProgressModule.forRoot(),
    CustomMaterialModule,
    RouterModule,
    NgxMaskModule
  ],
  providers: [],
  exports: [
    BanneredButtonComponent,
    AnalyticCircleComponent,
    SearchFormComponent,
    PopoverModule,
    CustomMaterialModule,
    FormsModule,
    ReactiveFormsModule,
    FormatNumberPipe,
    BreadcrumbComponent,
    HeaderComponent,
    LoaderComponent,
    StoreSelectComponent,
    CurrencyPipe,
    FilterListPipe,
    OrderByPipe,
    OrderListPipe,
    SafePipe,
    CommonBtnComponent,
    RequiredFieldsTableComponent,
    ConfirmDialogComponent,
    BrowserAgnosticDatePipe,
    IntegerInputDirective,
    MilitaryToStdPipe
  ]
})
export class SharedModule { }
