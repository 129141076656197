import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'formatNumber'
})
export class FormatNumberPipe implements PipeTransform {

  transform(value: any): any {
    let temp = value.substr(value.length -1);
    let ord = '';
    switch (temp) {
      case '1':
        return value+'st'; 
      case '2':
        return value+'nd'; 
      case '3':
        return value+'rd'; 
      default:
        return value+'th';
    }
  }

}
