import { Pipe, PipeTransform } from '@angular/core';
@Pipe({
  name: 'orderList',
  pure: true,
})
export class OrderListPipe implements PipeTransform {
  transform(array: [], args: string): any[] {
    if (array && array.length) {
      return array.sort((a, b) => a - b);
    }
    return array;
  }
}
