import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';

@Component({
  selector: 'app-store-select',
  templateUrl: './store-select.component.html',
  styleUrls: ['./store-select.component.scss'],
})
export class StoreSelectComponent implements OnInit {
  @Input() divisions;
  @Input() showSearch;
  @Input() showStoreSection = true;
  @Input() showAllSearch = true;
  @Input() searchBtn = true;
  @Input() multiStore = false;
  @Input() btnText = 'View';
  @Input() disableBtn = false;
  @Output() storesChange = new EventEmitter();
  @Output() searchStores = new EventEmitter();
  storeSelectForm: FormGroup;
  readonly separatorKeysCodes: number[] = [ENTER, COMMA];
  addOnBlur = true;
  removable = true;
  storeDisabled = false;

  constructor() {}

  ngOnInit() {
    this.storeSelectForm = new FormGroup({
      selectAll: new FormControl(),
      division: new FormControl(),
      selectedStores: new FormControl([]),
      singleStore: new FormControl()
    });
    this.listenForChanges();
  }

  listenForChanges() {
    this.storeSelectForm.valueChanges.subscribe((value) =>
      this.storesChange.emit(value)
    );
  }

  paste(event) {
    const pastedValues = [];
    event.preventDefault();
    const existingValues = this.storeSelectForm.get('selectedStores').value;
    event.clipboardData
      .getData('Text')
      .split(/;|,|\n/)
      .forEach((value) => {
        const storeId = value.trim();
        if (
          isNaN(storeId) ||
          Number(storeId) <= 0 ||
          existingValues.indexOf(storeId) !== -1 ||
          pastedValues.indexOf(storeId) !== -1
        ) {
          return;
        }
        pastedValues.push(storeId);
      });
    this.storeSelectForm
      .get('selectedStores')
      .patchValue(pastedValues.concat(existingValues));
    this.toggleStoreSelection('selectedStores');
  }

  removeStore(store) {
    const stores = this.storeSelectForm.get('selectedStores').value;
    stores.splice(
      stores.findIndex((storeStr) => storeStr === store),
      1
    );
    this.storeSelectForm.get('selectedStores').patchValue(stores);
    if (!stores.length) {
      this.storeSelectForm.enable({ emitEvent: false });
    }
  }

  addStore(event) {
    const storeId = event.input.value;
    event.input.value = '';
    const existingValues = this.storeSelectForm.get('selectedStores').value;
    if (
      !storeId ||
      isNaN(storeId) ||
      Number(storeId) <= 0 ||
      existingValues.indexOf(storeId) !== -1
    ) {
      return;
    }
    const valueEntered = [storeId];
    this.storeSelectForm
      .get('selectedStores')
      .patchValue(existingValues.concat(valueEntered));
    this.toggleStoreSelection('selectedStores');
  }

  toggleStoreSelection(controlName) {
    if (this.storeSelectForm.get(controlName).value) {
      // console.log(this.storeSelectForm.get(controlName).value)
      this.storeSelectForm.disable({ emitEvent: false });
      this.storeSelectForm.get(controlName).enable({ emitEvent: false });
    } else {
      this.storeSelectForm.enable({ emitEvent: false });
    }
    if ((controlName === 'selectAll' || controlName === 'division') && this.showSearch) {
      this.storeSelectForm.get('singleStore').patchValue(null);
      this.storeDisabled = !this.storeDisabled;
    }
  }

  emitStoreSearch() {
    this.searchStores.emit(true);
  }
}
