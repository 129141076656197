import { Component, OnInit } from '@angular/core';
import { Validators, FormGroup, FormControl, FormArray } from '@angular/forms';
import { formatDate } from '@angular/common';
import { SlotDiagnosticsService } from 'src/app/shared/services/slot-diagnostics/slot-diagnostics.service';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-slots-diagnostics-tool',
  templateUrl: './slots-diagnostics-tool.component.html',
  styleUrls: ['./slots-diagnostics-tool.component.scss']
})
export class SlotsDiagnosticsToolComponent implements OnInit {
  // Drop-downs
  dateDropDown: any[] = [];

  // FormGroup
  searchForm = new FormGroup({
    storeId: new FormControl(null, Validators.required),
    slotDate:  new FormControl(null, Validators.required)
  });

  // Search Button
  searchTxt = 'View';
  btnDisable: boolean = false;
  slotData: any;

  slotDiagnosticsTable:any;
  cols: Array<object> = [
    { header: "Service Type", field: "serviceType", },
    { header: "Slot Type", field: "slotType" },
    { header: "Slot Time", field: "slotTime" },
    { header: "Slot Length", field: "slotLength" },
    { header: "Slot Expiry", field: "slotExpiry" },
    { header: "Available Capacity", field: "availableCapacity" },
    { header: "Slot Mix Exception", field: "slotMixException" },
    { header: "Slot Exception", field: "slotException" },
    { header: "Slot Displayed", field: "slotDisplayed" }
  ];

  todayDate: string;

  constructor(private slotService: SlotDiagnosticsService, 
    public snackBar: MatSnackBar) {
    this.dateDropDown = this.getDates();
  }

  ngOnInit() {
    this.searchForm.patchValue({slotDate: formatDate(new Date(), 'yyyy-MM-dd', 'en-US')});
    this.todayDate = formatDate(new Date(), 'yyyy-MM-dd', 'en-US');
  }

  /** Returns either Red or Green text color for Feature Flags, Slot Info, Slot Controls based on their values */
  getTextColor(id, key) {
    let textColor;
    const textValue = this.slotData?.[id]?.[key]?.toString().toUpperCase();

    if (textValue === 'ENABLED' || textValue === 'AVAILABLE' || textValue === 'NOT SUPPRESSED') {
      textColor = 'rgb(26, 135, 91)'; // Green
    } else if (textValue === 'DISABLED' || textValue === 'NOT AVAILABLE' || textValue === 'SUPPRESSED') {
      textColor = 'rgb(198, 57, 57)'; // Red
    } else {
      textColor = 'black';
    }

    return textColor;
  };

  resetForm() {
    this.slotData = null;
  }

  getDates() {
    let date = new Date();
    let DAY = 1000 * 60 * 60 * 24;
    let i;
    let dateArray = [];

    // For Next 14 dates
    for (i = 0; i < 15; i++) {
      dateArray.push({value: formatDate(date, 'yyyy-MM-dd', 'en-US'), viewValue: formatDate(date, 'MM-dd-yyy', 'en-US')});
      date.setTime(date.getTime() + DAY);
    }

    // Sorting all dates
    dateArray.sort((a, b) => {
      const c = new Date(a.value);
      const d = new Date(b.value);
      return c.getTime() - d.getTime();
    });
    
    return dateArray;
  }

  formatPickUpTime(time: string) {
    if(time) {
      return time.startsWith('0') ? time.substring(1) : time;
    }
    return '';
  }

  fetchSlotData() {
    this.resetForm();
    this.searchTxt = 'Processing';
    this.btnDisable = true;

    const obs = this.slotService.getSlotData(this.searchForm.value.storeId, this.searchForm.value.slotDate);
    obs.subscribe(data => {
      this.slotData = data;
      this.searchTxt = 'View';
      this.btnDisable = false;

      this.snackBar.open('Successfully retrieved data.', 'Okay', {
        duration: 8000,
        panelClass: 'success-dialog'
      });
    }, err => {
      console.log(err);
      this.searchTxt = 'View';
      this.btnDisable = false;
      const errorMsg = err?.error?.message ? `Error: ${err.error.message}` : 'Service error, please check network logs.';

      this.snackBar.open(errorMsg, 'Okay', {
        duration: 8000,
        panelClass: 'error-dialog'
      });
    })
  }

}
