import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-common-btn',
  templateUrl: './common-btn.component.html',
  styleUrls: ['./common-btn.component.scss']
})
export class CommonBtnComponent implements OnInit {
  @Input() disableBtn;
  @Input() btnText = 'Update';
  @Output() sendUpdate = new EventEmitter();
  @Output() cancel = new EventEmitter();
  @Input() showCancel = false;

  constructor() { }

  ngOnInit(): void {
  }

  emitUpdate() {
    this.sendUpdate.emit('true');
  }

  emitCancel() {
    this.cancel.emit();
  }
}
