import { Directive, ElementRef, HostListener } from '@angular/core';

@Directive({
  selector: '[numbersOnly]'
})
export class NumbersOnlyDirective {

  constructor(public selectedEl: ElementRef) { }

  @HostListener('input', ['$event'])
  onInputChange(event) {
    const initialValue = this.selectedEl.nativeElement.value;

    this.selectedEl.nativeElement.value = initialValue.replace(/[^0-9]*/g, '');
    if ( initialValue !== this.selectedEl.nativeElement.value) {
      event.stopPropagation();
    }
  }
}
