import { Component, OnInit, Input, OnChanges } from '@angular/core';

@Component({
  selector: 'app-analytic-circle',
  templateUrl: './analytic-circle.component.html',
  styleUrls: ['./analytic-circle.component.scss']
})
export class AnalyticCircleComponent implements OnInit, OnChanges {
 
  @Input() numerator = 23;
  @Input() denominator = 45;
  @Input() percent= Math.round((this.numerator/this.denominator)*100);
  @Input() radius='40';
  @Input() space = '-10';
  @Input() unitsFontSize='20';
  @Input() unitsFontWeight='500';
  @Input() outerStrokeGradient=false;
  @Input() outerStrokeWidth='10';
  @Input() outerStrokeColor='#00a6a6';
  @Input() outerStrokeGradientStopColor='#008080';
  @Input() innerStrokeColor='#d4d4d4';
  @Input() innerStrokeWidth = '10';
  @Input() title= this.percent;
  @Input() subtitle = `${this.numerator}/${this.denominator}`;
  @Input() titleFontSize='20';
  @Input() titleFontWeight='500';
  @Input() subtitleFontSize='15';
  @Input() subtitleColor = '#A9A9A9';
  @Input() subtitleFontWeight='500';
  @Input() animateTitle =  'false';
  @Input() animationDuration = '800';
  @Input() showUnits = 'false';
  @Input() showBackground = 'false';
  @Input() startFromZero = 'false';
  @Input() type = "big";



  constructor() { }

  ngOnInit() {
    if(this.type === 'small') {
      this.radius='21';
      this.titleFontSize='9';
      this.subtitleFontSize='8';
      this.unitsFontSize='9'; 
      this.innerStrokeWidth='7';
      this.outerStrokeWidth='7';
      this.space = '-7';
    }

  }
  
  ngOnChanges() {
    // console.log(this.numerator, this.denominator);
    this.percent= Math.round((this.numerator/this.denominator)*100);
    this.title= this.percent;
    this.subtitle = `${this.numerator}/${this.denominator}`;
  }

}
