import { DatePipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'browserAgnosticDate'
})
export class BrowserAgnosticDatePipe extends DatePipe implements PipeTransform {
  transform(value: any, ...args): any {
    let isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);

    if (value && isSafari) {
      return super.transform(value.replace(/ /g, "T"), ...args);
    }

    return super.transform(value, ...args);
  }
}
