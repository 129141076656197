import { CanActivate, Router, ActivatedRouteSnapshot, RouterStateSnapshot, RouterLink } from '@angular/router';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { UserService } from '../user.service';
import { environment } from '../../../../environments/environment';
import { MatSnackBar } from '@angular/material/snack-bar';

interface UserConfig {
    group: Array<any>;
}

@Injectable()
export class RoleGuard implements CanActivate {
    private userConfig: UserConfig;

    constructor(private userService: UserService,
                private matDialog: MatSnackBar,
                private router: Router) {
    }

    canActivate(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot): Observable<boolean> | boolean {

        this.userService.UserConfig.subscribe(data => {
            // this.userConfig = { role: data['role'], group: data['group']};
            this.userConfig = data;
            // console.log('user role', data);
        });

        // console.log(state);
        // console.log(route.data);
        // console.log(this.userConfig);

        // console.log(this.userConfig.group.find(a => a.includes(route.data.group)));
        // if (this.userConfig.group.find(a => a.includes('az_osms_prod_all'))) {
        //     return true;
        // }

        if (this.userConfig && this.userConfig.group.some(a => route.data.group.includes(a))) {
            return true;
        }

        this.matDialog.open('Page access denied.', 'Okay', {
            duration: 4000,
            panelClass: 'error-dialog'
        });
        this.router.navigate(['/']);

        return false;
    }

}
