import { Pipe, PipeTransform } from '@angular/core';

@Pipe({name: 'militaryToStd'})
export class MilitaryToStdPipe implements PipeTransform {
  transform(time: any) {
    let b = time?.split(/\D/);
    if (!time) {
      return 'N/A';
    }
    if (time.indexOf('-') > -1) {
        return (b[0]%12 || 12) + ':' + b[1] + (b[0] <= 11? ' AM' : ' PM') + ' - ' + (b[2]%12 || 12) + ':' + b[3] + (b[2] <= 11? ' AM' : ' PM')
    }
    
    return (b[0]%12 || 12) + ':' + b[1] + (b[0] <= 11? ' AM' : ' PM');
  }
}
