import { Directive, HostListener, Input } from '@angular/core';

@Directive({
  selector: '[appIntegerInput]'
})
export class IntegerInputDirective {

  @Input()
  value: string;

  @HostListener('keydown', ['$event'])
  onKeydown(event: KeyboardEvent) {
    const key = event.key;
    const regex = /^[0-9]$/;

    // Allows only positive numbers
    if (!regex.test(key) && key !== 'Backspace') {
      event.preventDefault();
    } else {
    }

  }
}
