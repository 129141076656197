import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-bannered-button',
  templateUrl: './bannered-button.component.html',
  styleUrls: ['./bannered-button.component.scss']
})
export class BanneredButtonComponent implements OnInit {

  @Input() text = 'click';
  @Input() banner = 'safeway';
  @Input() index = 0;
  @Output() buttonClick = new EventEmitter<any>();
  
  constructor() { }

  ngOnInit() {
  }

  clicked(text) {
    return this.buttonClick.emit(text);
  }


}
