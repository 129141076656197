import { Injectable, SecurityContext } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ActivatedRoute } from '@angular/router';
import { StoreData } from '../interfaces/store-data.interface';
import { DomSanitizer } from '@angular/platform-browser';
import { EnvService } from '../context/env.service';
@Injectable({
  providedIn: 'root'
})
export class HttpService {
  unsafeURL: string;
  sanitizedUrl: any;
  public httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      'Cache-Control': 'no-cache'
    }),
    params: {}
  };
  userQueryParam: any;

  constructor(private http: HttpClient,
    private envService: EnvService,
    private domSanitizer: DomSanitizer,
    private route: ActivatedRoute) {
    this.route.queryParams.subscribe(data => this.userQueryParam = data.user);
  }

  buildHeaders(isFile?: boolean) {
    const builtHeaders = this.httpOptions;
    if (this.envService.environment.APIM_TRACE_HEADER_VALUE !== null) {
      builtHeaders.headers = builtHeaders.headers.set(this.envService.environment.APIM_TRACE_HEADER, this.envService.environment.APIM_TRACE_HEADER_VALUE);
    }
    if (this.envService.environment.STORE_APIM_SUBSCRIPTION_KEY_VALUE !== null) {
      builtHeaders.headers = builtHeaders.headers.set(this.envService.environment.APIM_SUBSCRIPTION_KEY_HEADER, this.envService.environment.STORE_APIM_SUBSCRIPTION_KEY_VALUE);
    }
    if (isFile) {
      builtHeaders.headers = builtHeaders.headers.delete('Content-Type');
    }
    return builtHeaders;
  }

  buildHeadersForOpp() {
    const builtHeaders = this.httpOptions;
    if (this.envService.environment.APIM_TRACE_HEADER_VALUE !== null) {
      builtHeaders.headers = builtHeaders.headers.set(this.envService.environment.APIM_TRACE_HEADER, this.envService.environment.APIM_TRACE_HEADER_VALUE);
    }
    if (this.envService.environment.OPP_APIM_SUBSCRIPTION_KEY_VALUE !== null) {
      builtHeaders.headers = builtHeaders.headers.set(this.envService.environment.APIM_SUBSCRIPTION_KEY_HEADER, this.envService.environment.OPP_APIM_SUBSCRIPTION_KEY_VALUE);
    }
    return builtHeaders;
  }

  uploadTplFile(fileToUpload: FormData) {
    this.httpOptions['responseType'] = 'text';
    const url = this.envService.environment.STORE_SLOC_URL + '/v2/store/tplconfig/override';
    return this.http.post(url, fileToUpload, this.buildHeaders(true));
  }

  uploadFileExpress(fileName: FormData) {
    this.httpOptions['responseType'] = 'text';
    const url = this.envService.environment.STORE_SLOC_URL + '/overide/bulk/express';
    return this.http.post(url, fileName, this.buildHeaders(true));
  }

  getStore(storeId: string) {
    const sanitizedUrl = this.domSanitizer.sanitize(SecurityContext.URL, this.envService.environment.STORE_SLOC_URL + '/v2/store/' + storeId + '?appn=EOT');
    // let headers = { headers: { 'client-id': '', 'Content-Type': 'application/json' } };
    // return this.http.get('../../assets/data/select-store.json');
    return this.http.get<StoreData>(sanitizedUrl, this.buildHeaders());
  }

  getAllStores() {
    return this.http.get<StoreData>(this.envService.environment.STORE_SLOC_URL + '/v2/store/allAvailableEcomstores?status=all', this.buildHeaders());
  }

  getCreateStore(storeId: string) {
    const sanitizedUrl = this.domSanitizer.sanitize(SecurityContext.URL, this.envService.environment.STORE_SLOC_URL + '/v2/storemaster/' + storeId);
    return this.http.get(sanitizedUrl, this.buildHeaders());
  }

  createStore(data) {
    return this.http.post<StoreData>(this.envService.environment.STORE_SLOC_URL + '/v2/store/', data, this.buildHeaders());
  }

  updateStore(data, storeId) {
    const sanitizedUrl = this.domSanitizer.sanitize(SecurityContext.URL, this.envService.environment.STORE_SLOC_URL + '/v2/store/' + storeId);
    return this.http.put(sanitizedUrl, data, this.buildHeaders());
  }

  updateDarkStoreInfo(data, storeId) {
    const sanitizedUrl = this.domSanitizer.sanitize(SecurityContext.URL, this.envService.environment.STORE_SLOC_URL + '/v2/store/updateDarkStoreInfo/' + storeId);
    return this.http.put(sanitizedUrl, data, this.buildHeaders());
  }

  getStoreBulkUpdate() {
    return this.http.get(this.envService.environment.STORE_SLOC_URL + '/v2/store/bulkUpdate/featureFlags', this.buildHeaders());
  }

  updateStoreBulkUpdate(store) {
    return this.http.put(this.envService.environment.STORE_SLOC_URL + '/v2/store/bulkUpdate/featureFlags', store, {
      ...this.buildHeaders(),
      observe: 'response'
    });
  }

  getMultiBanner() {
    return this.http.get(this.envService.environment.STORE_SLOC_URL + '/v2/store/multiBanner', this.buildHeaders());
  }

  updateMultiBannerZipcodes(body) {
    return this.http.put(this.envService.environment.STORE_SLOC_URL + '/v2/store/multiBannerZipCodes', body, this.buildHeaders());
  }

  updateFulfillmentStore(body) {
    return this.http.put(this.envService.environment.STORE_SLOC_URL + '/v2/store/fulfillmentStore', body, this.buildHeaders());
  }

  get3ppVendors() {
    const baseUrl = this.envService.environment.ENVIRONMENT_NAME.toLowerCase() === 'prod' ? this.envService.environment.OPP_RELATIVE_PATH : this.envService.environment.OPP_APIM_URL;
    const tplUrl = baseUrl + '/oppOperation/referenceCode?referenceCodeType=clientId-3pp';
    const sanitizedUrl = this.domSanitizer.sanitize(SecurityContext.URL, tplUrl);
    return this.http.get<any>(sanitizedUrl, this.buildHeadersForOpp());
  }
  // getToken() {
  //   if (window.location.href.indexOf('localhost') > -1) {
  //     const appendUri = 'https://erums-ecomhq.' + environment.environmentName.toLowerCase() + '.pcf.azwestus.stratus.albertsons.com';
  //     return this.http.get(appendUri + '/getToken');
  //   }
  //   return this.http.get('/getToken');
  // }


  ssoLogin() {
    return this.http.get('/vcap');
  }

  checkStoreId(storeId) {
    const numRegex = /^\d+$/;
    if (!numRegex.test(storeId)) {
      return null;
    }
    return storeId;
  }
}
