import { enableProdMode, Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { tap } from "rxjs/operators";
import { setProp } from "src/environments/properties";
import { environment } from "src/environments/environment";

@Injectable({
    providedIn: 'root'
})

export class EnvService {
    private env: { [key: string]: string } = window['env'] || {};
    private isLocal = environment.local;

    constructor(private http: HttpClient) { }

    loadEnvironmentConfig() {
        return this.http.get(this.isLocal ? '/osdp-eop-ui/api/fetchEnvVars' : '/api/fetchEnvVars').pipe(
            tap((config: any) => {
                this.env = config;
                setProp(this.env);
                if (config.PRODUCTION) {
                    enableProdMode();
                }
            })
        );
    }



    get environment() {
        return this.env
    }
}
