import { CanActivate, Router, ActivatedRouteSnapshot, RouterStateSnapshot, RouterLink } from '@angular/router';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { UserService } from '../user.service';
import { LdapService } from '../auth/ldap.service';
// import { BroadcastService, MsalService } from '@azure/msal-angular';
// import { environment } from '../../../../environments/environment';
import { MatSnackBar } from '@angular/material/snack-bar';
import { JwtHelperService } from '@auth0/angular-jwt';
import { EnvService } from '../../context/env.service';

@Injectable()
export class AuthGuard implements CanActivate {
    userConfig: any;
    signInUrl: string;
    remainingTime: number;

    constructor(public userService: UserService,
                public envService: EnvService,
                public ldap: LdapService,
                public matDialog: MatSnackBar,
                public router: Router) {
        if (localStorage.getItem('ldapExpiry')) {
            this.durationOfExpiry();
        }
    }

    durationOfExpiry() {
        const endDate = Number(localStorage.getItem('ldapExpiry'));
        const endYear = Number(new Date(localStorage.getItem('ldapExpiry')).getFullYear());
        const currentTime = new Date().getTime();
        const currentYear = new Date().getFullYear();
        const timeLeft = endDate - currentTime;
        const days = Math.floor(timeLeft / (1000 * 60 * 60 * 24));
        const hours = Math.floor((timeLeft % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
        const mins = Math.floor((timeLeft % (1000 * 60 * 60)) / (1000 * 60));
        const secs = Math.floor((timeLeft % (1000 * 60)) / 1000);

        this.remainingTime = mins;
        // console.log('remaining time:', this.remainingTime);
    }

    canActivate(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot): Observable<boolean> | boolean {

        const token = window.localStorage.getItem('ldapToken');
        const idToken = window.localStorage.getItem('ldapIDToken');
        const refreshToken = localStorage.getItem('refresh_token');
        const userId = localStorage.getItem('user-id');
        // const tokenNotExpired = this.checkTokenExpiry();

        // Check if user-config is set in localStorage already for role / group
        // Setting the role to be set in User Service to be able to read role/groups in components
        if (localStorage.getItem('user-config')) {
            this.userConfig = JSON.parse(atob(localStorage.getItem('user-config')));
            const jwt = new JwtHelperService();
            const decodedIDToken = jwt.decodeToken(idToken);

            decodedIDToken.groups = this.setEnvGroups(decodedIDToken);
            this.userService.setRole({ group: this.userConfig });

            // Below is checking user's group from within access token versus local storage
            if (!this.arraysEqual(this.userConfig.sort(), decodedIDToken.groups.sort())) {
                this.matDialog.open('Access Denied: Inconsistencies in user\'s group. Redirecting...', 'Okay', {
                    duration: 4900,
                    panelClass: 'error-dialog'
                });
                localStorage.clear();
                setTimeout(() => {
                    this.userService.logOut();
                }, 5000);
                return false;
            }
        }

        // If token exists, check for token then check if its still active
        if ((token && idToken) && this.remainingTime > 15) {
            this.setUserInfo(token, idToken);
            // this.router.navigate(['/store/create']);

            return true;
        // Else if token is expired and refreshToken exists, send refresh token to BE
        } else if (refreshToken && (this.remainingTime > 0 && this.remainingTime <= 15)) {
            // this.getToken(refreshToken, true);
            this.getRefreshToken(refreshToken);

            return true;

        // Else, check for "code" in queryParams then do post call to get token
        } else if (route.queryParams.code) {
            const code = route.queryParams.code;

            // Do POST call to get token info and set it in session or local storage
            // console.log('Got Code:', route.queryParams.code);
            this.getToken(code);
            return false;
        }

        // Send user to Microsoft Login to get code for token
        // localStorage.clear();
        this.userService.signIn();
        // this.msalService.loginRedirect();
        return false;
    }

    getToken(val, isRefresh?) {

        this.ldap.getToken(val, isRefresh).subscribe(data => {
            // console.log(data);
            const response: any = data;
            this.setLocalStorage(response);

            this.matDialog.open('✅ Successfully logged in.', 'Okay', {
                duration: 4000,
                panelClass: 'success-dialog'
            });
            const redirectUrl = localStorage.getItem('redirect_url');
            if (redirectUrl) {
                if (redirectUrl.indexOf('?') > -1 && redirectUrl.indexOf('&') <= 0) {
                    const splitUrl = redirectUrl.split('?')[1];
                    let obj = {};
                    obj[splitUrl.split('=')[0]] = splitUrl.split('=')[1];
                    this.router.navigate([redirectUrl.split('?')[0]], {queryParams: obj})
                } else if (redirectUrl.indexOf('?') > -1 && redirectUrl.indexOf('&') > -1) {
                    const splitUrl = redirectUrl.split('?')[1].split('&');
                    let obj = {};
                    for (let val of splitUrl) {
                        obj[val.split('=')[0]] = val.split('=')[1];
                    }
                    this.router.navigate([redirectUrl.split('?')[0]], {queryParams: obj})
                } else {
                    this.router.navigate([redirectUrl]);
                }
                
            } else {
                this.router.navigate(['/']);
            }
            return true;
        }, error => {
            console.log(error);
            this.userService.setLoggedState(false);

            if (error.status === 403) {
                this.matDialog.open('Error (403): Please connect to VPN. ', 'Okay', {
                    duration: 4000,
                    panelClass: 'error-dialog'
                });
            } else {
                this.matDialog.open('Error: ' + error.message, 'Okay', {
                    duration: 4000,
                    panelClass: 'error-dialog'
                });
            }
            return false;
        });
    }

    getRefreshToken(token) {
        // localStorage.clear();
        // this.setUserInfo(null, null);
        this.userService.setLoggedState(false);
        this.ldap.refreshToken(token).subscribe(data => {
            const response: any = data;
            this.setLocalStorage(response);

            this.matDialog.open('✅ Successfully logged in.', 'Okay', {
                duration: 4000,
                panelClass: 'success-dialog'
            });
            return true;
        }, error => {
            console.log(error);
            this.userService.setLoggedState(false);
            if (error.status === 403) {
                this.matDialog.open('Error (403): Please connect to VPN. ', 'Okay', {
                    duration: 4000,
                    panelClass: 'error-dialog'
                });
            } else {
                this.matDialog.open('Error: ' + error.message, 'Okay', {
                    duration: 4000,
                    panelClass: 'error-dialog'
                });
            }
            return false;
        });
    }

    private setUserInfo(token, idToken) {
        const jwt = new JwtHelperService();
        const decodedtoken = jwt.decodeToken(token);
        const decodedIDToken = jwt.decodeToken(idToken);

        if (decodedIDToken && decodedIDToken.groups) {
            const userId = decodedtoken.upn.split('@')[0];
            this.userService.setUserValue(userId, decodedtoken.name);
            decodedIDToken.groups = this.setEnvGroups(decodedIDToken);

            localStorage.setItem('user-id', userId);
            localStorage.setItem('user-config', btoa(JSON.stringify(decodedIDToken.groups)));
            this.userService.setRole({ group: JSON.parse(atob(localStorage.getItem('user-config'))) });
        }

    }

    private setLocalStorage(data) {
        let expiryInSeconds = Number(new Date().getTime() / 1000) + Number(data.expires_in);
        expiryInSeconds = expiryInSeconds * 1000;
        const currentTime = new Date().getTime();
        const timeLeft = expiryInSeconds - currentTime;
        const mins = Math.floor((timeLeft % (1000 * 60 * 60)) / (1000 * 60));

        localStorage.setItem('ldapToken', data['access_token']);
        localStorage.setItem('ldapIDToken', data['id_token']);
        localStorage.setItem('refresh_token', data['refresh_token']);
        localStorage.setItem('ldapExpiry', String(expiryInSeconds));
        this.userService.setLoggedState(true);
        this.setUserInfo(data['access_token'], data['id_token']);

        this.remainingTime = mins;
    }

    arraysEqual(a, b) {
        if (a === b) { return true; }
        if (a == null || b == null) { return false; }
        if (a.length !== b.length) { return false; }

        for (var i = 0; i < a.length; ++i) {
          if (a[i] !== b[i]) { return false; }
        }
        return true;
    }

    setEnvGroups(decodedIDToken) {
        const splitGrp = [];
        // decodedIDToken.groups = []; 
        // decodedIDToken.groups.push('az-osms-prod-storemgmtread');
        if (decodedIDToken.groups && decodedIDToken.groups.length) {
            for (let group of decodedIDToken.groups) {
                if ((group.indexOf('-nonprod-') > -1 || group.indexOf('-prod-') > -1)
                && this.envService.environment.ENVIRONMENT_ABBRV.toLowerCase() !== 'prd') {
                    splitGrp.push(group);
                }
                if (group.indexOf('-prod-') > -1 && this.envService.environment.ENVIRONMENT_ABBRV.toLowerCase() === 'prd') {
                    splitGrp.push(group);
                }
            }
        }

        // In lower environments push all users to master groups for all page access
        if (this.envService.environment.ENVIRONMENT_ABBRV.toLowerCase() !== 'prd' && !splitGrp.length) {
            splitGrp.push('az-osdp-prod-dpadevteamwrite', 'az-osdp-prod-businessmgmtwrite', 'az-osdp-prod-dpadevteamwritecns', 'az-osdp-prod-businessmgmtwritecns',);
        }

        // SA-1883 - Make Store Management Pages Available for all (Read Only Mode) in Prod
        if (this.envService.environment.ENVIRONMENT_ABBRV.toLocaleLowerCase() === 'prd') {
            splitGrp.push('az-osms-prod-storemgmtread', 'az-osms-prod-storemgmtreadcns');
        }

        decodedIDToken.groups = splitGrp;

        return decodedIDToken.groups;
    }
}
