import { Pipe, PipeTransform } from '@angular/core';

@Pipe({name: 'currency'})
export class CurrencyPipe implements PipeTransform {
  transform(value: any) {
    if (!value) {
      return 'Not Available';
    }
    return Number(value).toLocaleString('en-US', {style: 'currency', currency: 'USD'});
  }
}
