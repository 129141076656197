// Core Angular
import { BrowserModule } from '@angular/platform-browser';
import { APP_INITIALIZER, NgModule } from '@angular/core';

// Angular Material
import { CustomMaterialModule } from './material-module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatTooltipModule } from '@angular/material/tooltip';

// Date Modules
import { MatMomentDateModule } from '@angular/material-moment-adapter';

// Forms
import { FormsModule } from '@angular/forms';
import { ReactiveFormsModule } from '@angular/forms';

// Services
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { HttpService } from './shared/services/http.service';
import { AuthInterceptor } from './shared/services/auth/auth.interceptor';
import { SlotsService } from './slot-management/slots.service';

// Routing
import { AppRoutingModule } from './app-routing.module';

// NGX Mask
import { NgxMaskModule } from 'ngx-mask';
import { PhoneMaskDirective } from './shared/directives/phone-masking/phone-masking.directive';

// Modules
import { SharedModule } from './shared/shared.module';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { TableModule } from 'primeng/table';

// Authentication
import { AuthGuard } from './shared/services/auth/auth.guard';
import { RoleGuard } from './shared/services/auth/role.guard';

// Components
import { AppComponent } from './app.component';
import { HomeComponent } from './home/home.component';
import { ServiceWorkerModule } from '@angular/service-worker';
import { EnvService } from './shared/context/env.service';
// import { environment } from '../environments/environment';

const isIE = window.navigator.userAgent.indexOf('MSIE ') > -1 || window.navigator.userAgent.indexOf('Trident/') > -1;

export function initializeApp(environmentService: EnvService): () => Promise<any> {
  return (): Promise<any> => {
    return new Promise((resolve, reject) => {
      environmentService.loadEnvironmentConfig().subscribe({
        next: (env) => resolve(env),
        error: (err) => reject(err),
      });
    });
  };
}

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    PhoneMaskDirective,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    CustomMaterialModule,
    BrowserAnimationsModule,
    NgxMaskModule.forRoot(),
    SharedModule,
    ScrollingModule,
    MatTooltipModule,
    TableModule,
    MatMomentDateModule,
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: true,
      // Register the ServiceWorker as soon as the app is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: 'registerWhenStable:30000'
    }),
    // MsalModule.forRoot({
    //   auth: {
    //     clientId: 'dcb15331-af59-4b45-b8ae-821ef2574032',
    //     authority: 'https://login.microsoftonline.com/b7f604a0-00a9-4188-9248-42f3a5aac2e9/',
    //     redirectUri: window.location.origin,
    //     postLogoutRedirectUri: window.location.origin
    //   },
    //   cache: {
    //     cacheLocation: 'localStorage',
    //     storeAuthStateInCookie: isIE, // set to true for IE 11
    //   },
    //   }, {
    //     popUp: false,
    //     consentScopes: ['User.Read']
    // })
  ],
  providers: [HttpService, SlotsService, EnvService,
    AuthGuard,
    RoleGuard,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true
    },
    {
      provide: APP_INITIALIZER,
      useFactory: initializeApp,
      deps: [EnvService],
      multi: true,
    },
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
