export enum EnvironmentType {
    Local = 'local',
    QA = 'qa',
    QA2 = 'qa2',
    Acceptance = 'acceptance',
    Stage = 'stage',
    Production = 'production'
}

export function getHostname(): string {
    return window.location.hostname;
}

export function getEnvironment(host?: string): EnvironmentType {
    let hostname = host;

    if (!hostname) {
        hostname = getHostname();
    }

    switch (true) {
        case hostname.startsWith('osdp-eop-ui-qa1-west'):
            return EnvironmentType.QA;
        case hostname.startsWith('osdp-eop-ui-qa2-west'):
            return EnvironmentType.Acceptance;
        case hostname.startsWith('osdp-eop-ui-stage-west'):
            return EnvironmentType.Stage;
        case hostname.includes('ecom-eoa'):
            return EnvironmentType.Production;
        default:
            return EnvironmentType.Local;
    }
}

export const isProd = () => getEnvironment() === EnvironmentType.Production;
export const isLocal = () => window.location.hostname === 'localhost';

// Example usage:
export const environment = {
    production: isProd(),
    local: isLocal(),
    envType: getEnvironment()
};