import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { EnvService } from '../../context/env.service';
// import { environment } from '../../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class LdapService {
  public httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
    }),
    params: {}
  };
  // config = environment.authConfig;
  // apiUrl = this.config.apimUrl;

  constructor(private http: HttpClient, private envService: EnvService) {
    if (window.location.origin === 'https://ecom-eoa.albertsons.com' || window.location.origin === 'https://ecom-eoa-acceptance.albertsons.com') {
      this.envService.environment.AUTH_APIM_URL = this.envService.environment.AUTH_RELATIVE_PATH;
    }
  }

  buildHeaders(token?: string) {
    const builtHeaders = this.httpOptions;
    if (this.envService.environment.APIM_TRACE_HEADER_VALUE !== null) {
      builtHeaders.headers = builtHeaders.headers.set(this.envService.environment.APIM_TRACE_HEADER, this.envService.environment.APIM_TRACE_HEADER_VALUE);
    }
    if (this.envService.environment.AUTH_APIM_SUBSCRIPTION_KEY_VALUE !== null) {
      builtHeaders.headers = builtHeaders.headers.set(this.envService.environment.APIM_SUBSCRIPTION_KEY_HEADER, this.envService.environment.AUTH_APIM_SUBSCRIPTION_KEY_VALUE);
    }
    if (token !== undefined && token !== null) {
      builtHeaders.headers = builtHeaders.headers.set('Authorization', 'Bearer ' + token);
    }
    return builtHeaders;
  }

  getToken(codeVal, isRefresh?: boolean) {
    // console.log(codeVal);
    const localRedirectUrl = window.localStorage.getItem('redirect_url');
    let bodyJSON = {
      code: codeVal,
      redirectUrl: window.location.origin
    };

    const url = this.envService.environment.AUTH_APIM_URL + '/api/auth/token';
    return this.http.post(url, bodyJSON, this.buildHeaders());
  }

  refreshToken(token) {
    const body = {
      refreshToken: token,
      redirectUrl: window.location.origin
    };
    const url = this.envService.environment.AUTH_APIM_URL + '/api/auth/refreshToken';
    return this.http.post(url, body, this.buildHeaders());
  }

  validateToken() {
    const token = localStorage.getItem('ldapToken');
    const bodyJSON = {
      accessToken: token
    };
    const url = this.envService.environment.AUTH_APIM_URL + '/api/auth/validateToken';
    this.httpOptions['responseType'] = 'text';
    return this.http.post(url, bodyJSON, this.buildHeaders());
  }

}
