import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class PageService {
  whichPage = new BehaviorSubject<number>(1);

  constructor() {}

  get WhichPage(): Observable<number> {
    return this.whichPage.asObservable();
  }

  setFAQPage(page: number) {
    this.whichPage.next(page);
  }  

}
