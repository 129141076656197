import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';


@Component({
  selector: 'app-search-form',
  templateUrl: './search-form.component.html',
  styleUrls: ['./search-form.component.scss']
})
export class SearchFormComponent implements OnInit {
  @Input() buttonName = 'Search';
  @Input() placeholder = 'Type here for search';
  @Input() showLabel = false;
  @Input() labelValue= 'Name';
  @Output() search = new EventEmitter();
  searchText = '';

  constructor() { }

  ngOnInit() {
  }

  onSearch(){
    // console.log(this.searchText);
    this.search.emit(this.searchText);
  }

}
