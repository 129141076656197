// This file will be used to store static text, variables, and uri(s)

// import { environment } from './environment';

export const setProp = (props?: any) => {
  const properties = {
    homePage: [
      {
        pageGroup: 'Store Management',
        showGroup: true,
        titleColor: 'home-txt1',
        showTitle: false,
        pageData: [
          {
            pageName: 'Store Create',
            loginRequired: true,
            bgColor: 'home-bg1',
            route: '/store/create',
            group: ['storemgmt', 'shopexpsupport', 'storedeployteam', 'dpadevteamwrite'],
            showPage: false,
            desc: 'Create a new store with configurable features',
          },
          {
            pageName: 'Store List / Update',
            loginRequired: true,
            bgColor: 'home-bg1',
            route: '/store/list',
            group: ['storemgmt', 'shopexpsupport', 'storedeployteam', 'dpadevteamwrite', 'businessmgmtwrite'],
            showPage: false,
            desc: 'View list of stores and update settings for existing eCom store(s)',
          },
          {
            pageName: 'Store Shift Details',
            loginRequired: true,
            bgColor: 'home-bg1',
            route: '/store/shift',
            group: ['storemgmt', 'dpadevteamwrite'],
            showPage: false,
            desc: 'View existing or create new store shift details',
          },
          {
            pageName: 'Store Bulk Update',
            loginRequired: true,
            bgColor: 'home-bg1',
            route: '/store/bulk-update',
            group: ['shopexpsupport', 'storedeployteam', 'businessmgmt', 'dpadevteamwrite'],
            showPage: false,
            desc: 'Update store settings for multiple stores',
          },
          {
            pageName: 'Multi Banner MFC',
            loginRequired: true,
            bgColor: 'home-bg1',
            route: '/store/multibanner-zipcodes',
            group: ['shopexpsupport', 'storedeployteam', 'businessmgmt', 'dpadevteamwrite'],
            showPage: false,
            desc: 'View or update mapping for Multi Banner zip codes, eCom stores, and fulfillment stores',
          },
          // {
          //   pageName: 'Store Bulk Upload',
          //   loginRequired: true,
          //   bgColor: 'home-bg1',
          //   route: '/store/bulk-upload',
          //   group: ['shopexpsupport', 'storedeployteam', 'businessmgmt', 'dpadevteamwrite'],
          //   showPage: false,
          //   desc: 'EOT bulk upload for various store settings',
          // },
          {
            pageName: 'MTO DEPT SETUP',
            bgColor: 'home-bg1',
            route: '/store/mto',
            group: [
              'dpadevteamwrite',
              'businessmgmtwrite',
              'mtoismwrite'
            ],
            showPage: false,
            desc: 'Setup the configuration by service department to enable services at the MTO counters within stores.',
          },
        ],
      },
      {
        pageGroup: 'Promise Platform Management',
        showGroup: true,
        titleColor: 'home-txt6',
        showTitle: false,
        pageData: [
          {
            pageName: 'View/Manage Store Capacity',
            bgColor: 'home-bg6',
            route: props?.FFC_APP_URL + '/promise-management/eoa-react/eop/store-capacity',
            isExternalLink: true,
            group: [
              'businessmgmtwrite',
              'dpadevteamwrite',
              'slotmgmt',
              'readonlyuser',
              'storecapacityread',
              'storecapacitywrite',
              'generalaccessgrpread'
            ],
            showPage: false,
            desc: 'Manage (View/Edit) store unit capacity by Labor Hour.',
          },
          {
            pageName: 'Store Capacity Overrides Tracker',
            bgColor: 'home-bg6',
            route: props?.FFC_APP_URL + '/promise-management/eoa-react/eop/store-capacity-overrides',
            isExternalLink: true,
            group: [
              'businessmgmtwrite',
              'dpadevteamwrite',
              'slotmgmt',
              'readonlyuser',
              'generalaccessgrpread'
            ],
            showPage: false,
            desc: 'Historical report of store unit capacity adjustments.',
          },
          {
            pageName: 'Bulk Upload (Override) for Piece Count',
            bgColor: 'home-bg6',
            route: '/promise-platform/bulk-upload',
            group: [
              'dpadevteamwrite',
              'businessmgmtwrite',
            ],
            showPage: false,
            desc: 'Change store unit capacity by Labor Hour for Promise Platform (Bulk Upload).',
          },
          {
            pageName: 'DisplaySlotType to CapPool Mapping',
            bgColor: 'home-bg6',
            route: '/promise-platform/cap-pool',
            group: [
              'dpadevteamwrite',
              'businessmgmtwrite',
            ],
            showPage: false,
            desc: 'Manage capacity pool assignment for various Display Slot Types.',
          },
          {
            pageName: 'Pricing Type',
            bgColor: 'home-bg6',
            route: '/promise-platform/pricing-type',
            group: [
              'dpadevteamwrite',
              'businessmgmtwrite',
            ],
            showPage: false,
            desc: 'Define slot pricing for various Display Slot Types.',
          },
          {
            pageName: 'Comprehensive Eligible Offering',
            bgColor: 'home-bg6',
            route: '/promise-platform/ceo',
            group: [
              'dpadevteamwrite',
              'businessmgmtwrite',
            ],
            showPage: false,
            desc: 'Manage Display Slot Type eligibility based on customer profile and fulfillment type.',
          },
          {
            pageName: 'Capacity Management Levers',
            bgColor: 'home-bg6',
            route: '/promise-platform/capacity-management',
            group: [
              'dpadevteamwrite',
              'businessmgmtwrite',
            ],
            showPage: false,
            desc: 'Manage store unit capacity levers (E.g. CapPool allocation%, buffer, and default PPQH).',
          },
          {
            pageName: 'Master Slot Template Management',
            bgColor: 'home-bg6',
            route: '/promise-platform/mst-management',
            group: [
              'dpadevteamwrite',
              'businessmgmtwrite',
            ],
            showPage: false,
            desc: 'Define comprehensive list of slot offerings.',
          },
          {
            pageName: 'Pattern Editor Management',
            bgColor: 'home-bg6',
            route: '/promise-platform/pattern-editor',
            group: [
              'dpadevteamwrite',
              'businessmgmtwrite',
            ],
            showPage: false,
            desc: 'Align group of stores with appropriate Capacity Management Lever and Master Slot Template.',
          },
          {
            pageName: 'Bulk Upload for Slot Exceptions',
            bgColor: 'home-bg6',
            route: '/promise-platform/opp-exceptions-upload',
            group: [
              'dpadevteamwrite',
              'businessmgmtwrite',
            ],
            showPage: false,
            desc: 'Create exceptions to suppress slots.',
          },
          {
            pageName: 'Slot Exceptions Management',
            bgColor: 'home-bg6',
            route: '/promise-platform/opp-exceptions',
            group: [
              'dpadevteamwrite',
              'businessmgmtwrite',
            ],
            showPage: false,
            desc: 'Add, Search, and View Slot Exceptions with options to Edit and Disable them.',
          },
          {
            pageName: 'Bulk Upload for MST Groups',
            bgColor: 'home-bg6',
            route: '/promise-platform/mst-bulk-upload',
            group: [
              'dpadevteamwrite',
              'businessmgmtwrite',
            ],
            showPage: false,
            desc: 'Upload a CSV file for creating new MST Groups.',
          },
          {
            pageName: 'Additional/Schedule Capacity Adjustment',
            bgColor: 'home-bg6',
            route: '/promise-platform/vc-upload',
            group: [
              'dpadevteamwrite',
              'businessmgmtwrite',
            ],
            showPage: false,
            desc: 'Upload a CSV file to change the capacity value.',
          },
          {
            pageName: ' Bulk Upload for Slot Mix Limit Exceptions',
            bgColor: 'home-bg6',
            route: '/promise-platform/slot-mix-limit-exceptions',
            group: [
              'dpadevteamwrite',
              'businessmgmtwrite',
            ],
            showPage: false,
            desc: 'Upload a CSV file for suppressing Slots based on slot mix limit in OPP.',
          },
          {
            pageName: 'Slot Mix Limit Exceptions - Search, View & Disable',
            bgColor: 'home-bg6',
            route: '/promise-platform/slot-mix-limit-exceptions-search',
            group: [
              'dpadevteamwrite',
              'businessmgmtwrite',
            ],
            showPage: false,
            desc: 'Search & view the slot mix limit exceptions.',
          },
          {
            pageName: 'Slot Controls',
            bgColor: 'home-bg6',
            route: props?.FFC_APP_URL + '/promise-management/eoa-react/eop/slot-controls',
            isExternalLink: true,
            group: [
              'dpadevteamwrite',
              'businessmgmtwrite',
              'storemgmtwrite',
            ],
            showPage: false,
            desc: 'Turn On/Off DUG or Delivery',
          }
        ],
      },
      {
        pageGroup: 'Slot Management',
        showGroup: false,
        titleColor: 'home-txt2',
        showTitle: false,
        pageData: [
          {
            pageName: 'Slot Upload & Update',
            loginRequired: true,
            bgColor: 'home-bg2',
            route: '/slot/slotupload',
            group: ['slotmgmt', 'dpadevteamwrite'],
            showPage: false,
            desc: 'Upload or Update slots using a CSV file',
          },
          {
            pageName: 'Slot Override(s)',
            loginRequired: true,
            bgColor: 'home-bg2',
            route: '/slot/exceptions',
            group: ['slotmgmt', 'dpadevteamwrite'],
            showPage: false,
            desc: 'Create new overrides for slots',
          },
          {
            pageName: 'Master Slot Template',
            loginRequired: true,
            bgColor: 'home-bg2',
            route: '/slot/masterslot',
            group: ['slotmgmt', 'dpadevteamwrite'],
            showPage: false,
            desc: 'Update/Upload values for Master Slot Template',
          },
          {
            pageName: 'Slot Change Report',
            bgColor: 'home-bg2',
            route: '/slot/override/history',
            group: ['slotmgmt', 'dpadevteamwrite'],
            showPage: false,
            desc: 'Historical report of adjustments made to slots from Slot Overrides, OTT Score Adjustments and Intraday Adjustments',
          },
          {
            pageName: 'Master Slot Template Bulk Update',
            bgColor: 'home-bg2',
            route: '/slot/mst/bulk/actions',
            group: ['shopexpsupport', 'storedeployteam', 'businessmgmt', 'dpadevteamwrite'],
            showPage: false,
            desc: 'Update master slot template for multiple stores',
          }
        ],
      },
      {
        pageGroup: 'Labor Management',
        showGroup: true,
        titleColor: 'home-txt3',
        showTitle: false,
        pageData: [
          {
            pageName: 'OTT Score Exemption',
            loginRequired: true,
            bgColor: 'home-bg3',
            route: '/levers/ott/exceptions',
            group: ['leversmgmt', 'dpadevteamwrite'],
            showPage: false,
            desc: 'Adjust OTT Score calculation when OTT should be negated due to extenuating circumstances',
          },
          {
            pageName: 'Workload Exceptions',
            loginRequired: true,
            bgColor: 'home-bg3',
            route: '/levers/workload/exceptions',
            group: ['leversmgmt', 'dpadevteamwrite'],
            showPage: false,
            desc: 'Workload Staffing Start/End Time Exceptions',
          },
          {
            pageName: 'Master Schedule Exceptions',
            loginRequired: true,
            bgColor: 'home-bg3',
            route: '/levers/masterschedule',
            group: ['leversmgmt', 'dpadevteamwrite', 'slotmgmt', 'businessmgmtwrite'],
            showPage: false,
            desc: 'Update configuration for Master Schedule Exception table',
          },
        ]
      },
      {
        pageGroup: 'Slot Report',
        showGroup: true,
        titleColor: 'home-txt4',
        showTitle: false,
        pageData: [
          // {
          //   pageName: 'Slot Capacity',
          //   bgColor: 'home-bg4',
          //   route: '/slot/slotcapacity',
          //   group: ['capacity'],
          //   showPage: false,
          //   desc: 'Check on Slot Capacity per given Store ID',
          // },
          {
            pageName: 'DCP Control Center',
            bgColor: 'home-bg4',
            route: props?.FFC_APP_URL + '/promise-management/eoa-react/eop/dcp-control-center/slot-diagnostics-tool',
            isExternalLink: true,
            group: ['capacity', 'generalaccessgrpread'],
            showPage: false,
            desc: 'Centralized application to monitor and manage the slots',
          }
        ]
      },
      {
        pageGroup: 'Labor Planning',
        showGroup: true,
        titleColor: 'home-txt7',
        showTitle: false,
        pageData: [
          {
            loginRequired: true,
            pageName: 'Sales and Labor Planning',
            bgColor: 'home-bg7',
            route: props?.FFC_APP_URL + '/promise-management/eoa-react/acuforecast/all-divisions',
            group: ['acuforecast', 'dpadevteamwrite'],
            showPage: false,
            isExternalLink: true,
            desc: 'Manage demand forecast for labor planning',
          },
        ],
      },
      {
        pageGroup: 'Promotions Management',
        showGroup: true,
        titleColor: 'home-txt5',
        showTitle: false,
        pageData: [
          {
            loginRequired: true,
            pageName: 'Promocode Upload and Misuse',
            bgColor: 'home-bg5',
            route: '/promocode-upload',
            group: ['promotionsmgmt', 'shopexpsupport', 'dpadevteamwrite'],
            showPage: false,
            desc: 'Upload a CSV file containing promo codes or promo misuse',
          },
        ],
      },
      {
        pageGroup: 'Training & FAQ',
        showGroup: true,
        titleColor: 'home-txt1',
        showTitle: false,
        pageData: [
          {
            pageName: 'Training and FAQ',
            bgColor: 'home-bg1',
            route: '/help/faq',
            group: ['help', 'dpadevteamwrite'],
            showPage: false,
            desc: 'Frequently Asked Questions about slot features, reporting and access.',
          },
        ],
      },
    ],
  };

  return properties;
};
