import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { Injectable, Injector } from '@angular/core';
import { UserService } from '../user.service';
import { switchMap, catchError } from 'rxjs/operators';
// import { environment } from './../../../../environments/environment';
import { MatSnackBar } from '@angular/material/snack-bar';
import { EnvService } from '../../context/env.service';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
    user: string;
    // config = environment.authConfig;
    public httpOptions = {
        headers: new HttpHeaders({
            'Content-Type': 'application/json',
        }),
        params: {}
    };
    // apiUrl = this.config.apimUrl;

    constructor(private userService: UserService, 
        private envService: EnvService,
        public matDialog: MatSnackBar,
        private injector: Injector, 
        private http: HttpClient){
        this.userService.UserValue.subscribe(data => {this.user = data});
        if (!this.user) {
            this.user = localStorage.getItem('user-id');
        }
    }

    buildHeaders(token?: string) {
        const builtHeaders = this.httpOptions;
        if (this.envService.environment.APIM_TRACE_HEADER_VALUE !== null) {
          builtHeaders.headers = builtHeaders.headers.set(this.envService.environment.APIM_TRACE_HEADER, this.envService.environment.APIM_TRACE_HEADER_VALUE);
        }
        if (this.envService.environment.AUTH_APIM_SUBSCRIPTION_KEY_VALUE !== null) {
          builtHeaders.headers = builtHeaders.headers.set(this.envService.environment.APIM_SUBSCRIPTION_KEY_HEADER, this.envService.environment.AUTH_APIM_SUBSCRIPTION_KEY_VALUE);
        }
        if (token !== undefined && token !== null) {
          builtHeaders.headers = builtHeaders.headers.set('Authorization', 'Bearer ' + token);
        }
        return builtHeaders;
    }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        // console.log('In auth interceptor');
        // Setting User ID in Header for every API call
        if (this.user) {
            req = req.clone({
                setHeaders: {
                    'X-User-Id': this.user
                }
            });
        }

        // The following is implemented to validate LDAP Token before subsequent API calls
        if (!req.url.includes("api/fetchEnvVars") && (req.url !== this.envService.environment.AUTH_APIM_URL+ '/api/auth/validateToken' && req.url.indexOf('ecommhqauthservice') < 1)) {
            const token = localStorage.getItem('ldapIDToken');
            const http: HttpClient = this.injector.get(HttpClient);
            const bodyJSON = {
                accessToken: token
            };
            this.httpOptions['responseType'] = 'text';

            if (window.location.origin === 'https://ecom-eoa.albertsonsthis.config.relativePath.com' || window.location.origin === 'https://ecom-eoa-acceptance.albertsons.com') {
                this.envService.environment.AUTH_APIM_URL = this.envService.environment.AUTH_RELATIVE_PATH;
            }

            return http.post(this.envService.environment.AUTH_APIM_URL + '/api/auth/validateToken', bodyJSON, this.buildHeaders()).pipe(switchMap((response) => {
                return next.handle(req);
            }), catchError(error => {
                // console.log(error);
                if (error) {
                    if (error.status === 403) {
                        this.matDialog.open('Error (403): Please connect to VPN. ', 'Okay', {
                            duration: 8000,
                            panelClass: 'error-dialog'
                        });
                    }
                    return throwError(error);
                }
            }));

        } else {
            return next.handle(req);
        }

        // Below is the previous code
        // return next.handle(req);

        // console.log('authRequest', req);
    }
}
