import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-required-fields-table',
  templateUrl: './required-fields-table.component.html',
  styleUrls: ['./required-fields-table.component.scss']
})
export class RequiredFieldsTableComponent implements OnInit {
  @Input() dataSource:any[];
  displayedColumns: string[] = ['field', 'type', 'example'];

  constructor() { }

  ngOnInit(): void {
  }

}
