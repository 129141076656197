import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable, SecurityContext } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { EnvService } from '../../context/env.service';

@Injectable({
  providedIn: 'root'
})
export class SlotDiagnosticsService {

  public httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      'Cache-Control': 'no-cache'
    }),
    params: {}
  };

  constructor(private http: HttpClient, private sanitizer: DomSanitizer, private envService: EnvService) {
    if (window.location.origin === 'https://ecom-eoa.albertsons.com' || window.location.origin === 'https://ecom-eoa-acceptance.albertsons.com') {
      this.envService.environment.OPP_APIM_URL = this.envService.environment.OPP_RELATIVE_PATH;
    }
  }

  buildHeaders() {
    const builtHeaders = this.httpOptions;
    if (this.envService.environment.APIM_TRACE_HEADER_VALUE !== null) {
      builtHeaders.headers = builtHeaders.headers.set(
        this.envService.environment.APIM_TRACE_HEADER,
        this.envService.environment.APIM_TRACE_HEADER_VALUE
      );
    }
    if (this.envService.environment.OPP_APIM_SUBSCRIPTION_KEY_VALUE !== null) {
      builtHeaders.headers = builtHeaders.headers.set(
        this.envService.environment.APIM_SUBSCRIPTION_KEY_HEADER,
        this.envService.environment.OPP_APIM_SUBSCRIPTION_KEY_VALUE
      );
    }
    return builtHeaders;
  }

  getSlotData(storeId: string, slotDate: string) {
    // const mockUrl = '../../assets/data/mock.json';
    const fullUrl = this.envService.environment.OPP_APIM_URL + `/oppOperation/getSlotDiagServiceDetails/${storeId}/${slotDate}`;
    const sanitizedUrl = this.sanitizer.sanitize(SecurityContext.URL, fullUrl);
    return this.http.get(sanitizedUrl, this.buildHeaders());
  }
}
