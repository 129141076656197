import { Pipe, PipeTransform } from '@angular/core';
@Pipe({
  name: 'orderBy',
  pure: true,
})
export class OrderByPipe implements PipeTransform {
  transform(array: any, field: string): any[] {
    if (field === 'shiftName' || field === 'slotStartTime') {
      array.sort((a: any, b: any) => {
        return a[field].localeCompare(b[field], 'en', { numeric: true });
      });
    } else {
      array.sort((a: any, b: any) => {
        if (a[field] < b[field]) {
          return -1;
        }
        if (a[field] > b[field]) {
          return 1;
        }
        return 0;
      });
    }
    return array;
  }
}
