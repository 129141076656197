import { Component, OnInit } from '@angular/core';
import { SlotsService } from '../slots.service';
import { AlertComponent } from 'ngx-bootstrap/alert/alert.component';
import { UserService } from '../../shared/services/user.service';
import { MatSnackBar } from '@angular/material/snack-bar';
@Component({
  selector: 'app-slots-report',
  templateUrl: './slots-report.component.html',
  styleUrls: ['./slots-report.component.scss']
})
export class SlotsReportComponent implements OnInit {
  btnTxt = 'Search';

  numerator = 50;
  denominator = 100;

  labelValue = 'Store ID';
  showLabel = false;
  placeholder= 'Enter store ID';
  searchText = '68';

  weeklyDetails;
  weeklyDetailsMap = {};
  tableHeader;
  data: any;
  weeks = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];

  color = 'primary';
  mode = 'determinate';
  value = 50;
  imgUrl: string;
  stores: any;
  current = 0;
  previous = 0;
  next =  0;
  threshold = 80;
  autoTicks = false;
  disabled = false;
  invert = false;
  max = 100;
  min = 25;
  showTicks = false;
  step = 5;
  thumbLabel = false;
  vertical = false;
  alerts: any[] = [];
  loading: boolean;
  highestShift = false;
  highestSlot = false;
  date: any;
  shiftNameOrCapacity = false;

  isLoggedIn: boolean;

  constructor(private slotsService: SlotsService, private userService: UserService, private snackBar: MatSnackBar) {
    this.userService.loggedState.subscribe(data => {
      this.isLoggedIn = data;
    });
  }

  ngOnInit() {

    if (!this.isLoggedIn) {
      this.userService.signIn();
    }

    this.slotsService.getMostViewedStores().subscribe(data => {
      this.stores = data;
      let tempLocal = this.getLocalStorage();
      if(tempLocal) {
        this.stores = tempLocal;
      } else {
        this.setLocalStorage();
      }
    });  
  }

  toggleShiftNameOrSlot() {
    this.shiftNameOrCapacity = !this.shiftNameOrCapacity;
  }
  getLocalStorage() {
    return JSON.parse(localStorage.getItem('recentSearch'));
  }

  setLocalStorage() {
    localStorage.setItem('recentSearch', JSON.stringify(this.stores));
  }

  handleError(error) {
    this.snackBar.open(error?.status + ': ' + error?.error?.developerMessage, 'Okay',{
      duration: 6000,
      panelClass: 'error-dialog'
    });
    // this.alerts.pop();
    // this.alerts.push({
    //   type: 'danger',
    //   msg: `Error calling Cpacity Report with status - ${error.status}. (added: ${new Date().toLocaleTimeString()})`,
    //   timeout: 5000
    // });
  }


  onClosed(dismissedAlert: AlertComponent): void {
    this.alerts.pop();
  }


  onSearch(searchText){
    this.populateDataForSlotsReportTable(searchText, this.current);
  }

  onNextClicked(){
    this.populateDataForSlotsReportTable(this.searchText, this.next);
  }

  onPreviousClicked(){
    this.populateDataForSlotsReportTable(this.searchText, this.previous);
  }

  onCurrentClicked() {
    this.populateDataForSlotsReportTable(this.searchText, this.current);
  }

  setCurrentNextPrevious(value) {
    if(value === 0) {
      this.next = 1;
      this.previous = -1;
    } else {
      this.next = value + 1;
      this.previous = value - 1;
    }
    
  }


  populateDataForSlotsReportTable(searchText: string, pageIndex){
    this.loading = true;
    this.btnTxt = 'Processing';
    this.slotsService.getSlotsCapacityReportupdated(searchText, pageIndex).subscribe(data => {
      this.btnTxt = 'Search';
      this.searchText = searchText;
      this.data = data;
      this.setMostRecentSearchedStoreInformation(this.searchText, this.data.banner);
      this.loading = false;
      // this.current = data['numberOfWeek'];
      this.setCurrentNextPrevious(pageIndex);
      this.weeklyDetails = this.data.weeklyReportDetails.dailyDetails;
      this.processTheDayOfTheWeekArray(this.data.weeklyReportDetails.startAndEndDate);
      this.data.weeklyReportDetails.startAndEndDate = this.formatStartAndEndDate(this.data.weeklyReportDetails.startAndEndDate);
      this.weeklyDetails.map(weeklyDetail => {
        this.weeklyDetailsMap[weeklyDetail.dayOfTheWeek] = {
          date: this.date[weeklyDetail.dayOfTheWeek],
          shiftDetails: weeklyDetail.shiftDetails,
          slotsCapacityDetails: weeklyDetail.slotsCapacityDetails
        }
      });

      if (this.weeklyDetailsMap['Sunday'].shiftDetails && this.weeklyDetailsMap['Sunday'].shiftDetails.length) {
        this.highestShift = this.weeklyDetailsMap['Sunday'].shiftDetails.length >= 5;
        this.highestSlot = this.weeklyDetailsMap['Sunday'].slotsCapacityDetails.length >= 5;
      }
      // console.log(this.weeklyDetailsMap);
      // if(this.data && this.data.banner != undefined && this.data.banner) {
      // this.imgUrl = this.processImgurl(this.data.banner);
      // } else {
      //   this.imgUrl = '';
      // }
    }, (error) => {
      this.btnTxt = 'Search';
      this.data = '';
      this.loading = false;
      this.handleError(error);
    });
  }

  setMostRecentSearchedStoreInformation(storeId, banner) {
    let tempId = parseInt(storeId);
    if(!this.checkStoreAlreadyExists(tempId)) {
      if(this.stores && this.stores.length >= 13) {
        this.stores.pop();
      }
      this.stores.unshift(
        {
          storeId: tempId,
          banner: banner.toLowerCase(),
          count: 1
        }
      );
      this.setLocalStorage();
    }
  }

  checkStoreAlreadyExists(storeId) {
    let temp = false;
    this.stores.forEach(element => { 
      if(element.storeId == storeId) {
        element.count++;
        temp= true;
      }
    });
    return temp;
  }

  processTheDayOfTheWeekArray(completeDate) {
    let spaceSeperated = completeDate.split(' ');
    let hyphenSeperated = spaceSeperated[0].split('-');
    this.date = null;
    this.date = new Date(hyphenSeperated[0],hyphenSeperated[1]-1,hyphenSeperated[2],0,1,1);
    let temp = [];
    this.weeks.forEach( week => {
      if(week != 'Sunday') {
        this.date.setDate(this.date.getDate() + 1);
        temp[week] = this.date.getFullYear()+'-'+(this.date.getMonth()+1)+'-'+this.date.getDate();
      } else {
        temp[week] = this.date.getFullYear()+'-'+(this.date.getMonth()+1)+'-'+this.date.getDate();
      }
    }
    );
    this.date = temp;
  }

  formatStartAndEndDate(dateText) {
    let spaceSeperated = dateText.split(' ');
    return this.formatDate(spaceSeperated[0]) +' - '+ this.formatDate(spaceSeperated[2]);
  }

  formatDate(date) {
    let temp = date.split('-');
    return temp[1]+'/'+temp[2];
  }

  getDateOfWeek(weekDay, hoverStatus) {
     document.getElementById(weekDay).innerHTML = hoverStatus ? this.weeklyDetailsMap[weekDay].date : weekDay;
  }

  // processImgurl(banner) {
  //   switch(banner) {
  //     case 'vons':
  //       return 'https://www.safeway.com/content/dam/safeway/images/logos/Vons_RGB.svg';
  //     case 'safeway':
  //       return 'https://www.safeway.com/content/dam/logos/Safeway_RGB.svg';
  //     case 'randalls':
  //       return 'https://www.safeway.com/content/dam/safeway/images/logos/Randalls_RGB.svg';
  //     case 'jewelosco':
  //         return 'https://www.jewelosco.com/content/dam/logos/JewelOsco_Vert_Oval_RGB.svg';
  //     case 'albertsons':
  //         return 'https://www.safeway.com/content/dam/logos/Albertsons_RGB.svg';
  //     case 'pavilions':
  //       return 'https://www.pavilions.com/content/dam/safeway/images/logos/Pavilions_RGB.svg';
  //     case 'tomthumb':
  //       return 'https://www.safeway.com/content/dam/safeway/images/logos/TomThumb_RGB.svg';
  //   } 
  // }


  setAnlyticalCircleData(shiftDetail, type) {
    if(type == 'delivery') {
    this.numerator = shiftDetail.deliveryOrderCount;
    this.denominator = shiftDetail.deliveryCapacityCount;
    } else {
    this.numerator = shiftDetail.dugOrderCount;
    this.denominator = shiftDetail.dugCapacityCount;
    }
  }
}